.site-price{
	.pricebox{
		&__txt{
			color: $blue;
			border: $blue 2px solid;
			font-size: 1.24rem;
			font-weight: 700;
			text-align: center;
			padding: 12px 0;
			margin: 0 0 40px;
			@media screen and (max-width: $phone){
				font-size: 1.04rem;
				padding: 12px $sp-side;
				margin: 0 0 24px;
			}
		}
		&__list{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
			&__item{
				width: 32%;
				padding: 24px;
				border-radius: 4px;
				background: $gray;
				@media screen and (max-width: $phone){
					width: auto;
					padding: $sp-side;
					margin: 0 0 $sp-side;
					@include flex();
					align-items: center;
					justify-content: flex-start;
					&:last-child{
						margin-bottom: 0;
					}
				}
				figure{
					width: 120px;
					background: #FFF;
					padding: 16px;
					margin: 0 auto;
					border-radius: 50%;
					@media screen and (max-width: $phone){
						width: 88px;	
					}
					img{
						width: 100%;
					}
				}
				dl{
					line-height: 1.56;
					margin: 20px 0 0;
					@media screen and (max-width: $phone){
						width: calc(100% - 88px);
						padding: 0 0 0 20px;
						margin: 0;
					}
					dt{
						font-size: 1.24rem;
						font-weight: 700;
						text-align: center;
						margin: 0 0 8px;
						@media screen and (max-width: $phone){
							font-size: 1.12rem;
							text-align: left;	
						}
						.sml{
							font-size: .88rem;
							display: inline-block;
							margin: 0 0 0 8px;
						}
					}
					dd{
						color: $blue;
						font-size: 1.44rem;
						font-weight: 700;
						text-align: center;
						@media screen and (max-width: $phone){
							font-size: 1.32rem;
							text-align: left;	
						}
						strong{
							font-size: 2.4rem;
							line-height: 1;
						}
					}
				}
			}
		}
		&__note{
			font-size: .96rem;
			margin: 40px 0 0;
			padding: 24px;
			border: $gray 8px solid;
			border-radius: 3px;
			@media screen and (max-width: $phone){
				margin: 24px 0 0;
				padding: $sp-side;
			}
			li{
				position: relative;
				padding: 0 0 20px 28px;
				margin: 0 0 20px;
				border-bottom: darken($border, 5%) 1px dashed;
				@media screen and (max-width: $phone){
					text-align: justify;	
				}
				&:last-child{
					padding-bottom: 0;
					margin-bottom: 0;
					border: none;
				}
				&:before{
					@include fontawesome('\f00c');
					color: $blue;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
	
	.storages{
		padding: 32px 0 0;
		margin: 40px 0 0;
		position: relative;
		&:before{
			content: '';
			width: 48px;
			height: 2px;
			background: $blue;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}
		&__box{
			@include flex();
			align-items: center;
			border-bottom: $border 1px solid;
			@media screen and (max-width: $phone){
				display: block;	
			}
			&.rvs{
				> figure{
					order: 2;
				}
				> div{
					order: 1;
				}
			}
			&:last-of-type{
				border: none;
			}
			&__pic{
				width: 480px;
				@media screen and (max-width: $tab){
					width: 360px;	
				}
				@media screen and (max-width: $phone){
					width: 72%;
					margin: 0 auto 20px;
				}
				img{
					width: 100%;
				}
			}
			&__data{
				width: calc(100% - 480px - 56px);
				@media screen and (max-width: $tab){
					width: calc(100% - 360px - 48px);
				}
				@media screen and (max-width: $phone){
					width: auto;	
				}
				dl{
					dt{
						font-size: 1.88rem;
						line-height: 1.48;
						font-weight: 700;
						@media screen and (max-width: $phone){
							font-size: 1.32rem;
							text-align: center;
						}
						&:before{
							content: attr(data-txt);
							font-size: .92rem;
							color: $blue;
							line-height: 1;
							display: block;
						}
						strong{
							font-size: 2.64rem;
							color: $blue;
							display: inline-block;
							margin: 0 4px;
							@media screen and (max-width: $phone){
								font-size: 1.72rem;	
							}
						}
					}
					dd{
						font-size: .96rem;
						text-align: justify;
						margin: 20px 0 0;
						&:first-of-type{
							margin: 6px 0 0;
							@media screen and (max-width: $phone){
								margin: 6px $sp-side 0;	
							}
						}
						&.note{
							font-size: .92rem;
							background: darken($gray, 2%);
							padding: 10px 16px;
							border-radius: 3px;
						}
					}
				}
			}
		}
	}
}