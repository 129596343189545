.site-index{
	.about{
		width: 100%;
		position: relative;
		overflow: hidden;
    .cosoji{
      background: $gray;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 5.6rem;
      margin: 0 0 4rem;
      padding: 2rem;
      padding-left: 5.6rem;
      border-radius: .4rem;
      overflow: hidden;
      position: relative;
      @media screen and (max-width: $tab){
        gap: 2.4rem;
        padding-left: 2.4rem;
      }
      @media screen and (max-width: $phone){
        padding: 16px;
        gap: 16px;
        grid-template-columns: 1fr;
      }
      &:before,
      &:after{
        content: '';
        width: 8rem;
        height: 8rem;
        background: #2dcbc8;
        position: absolute;
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 0 100%);
        @media screen and (max-width: $tab){
          width: 4rem;
          height: 4rem;
        }
        @media screen and (max-width: $phone){
          width: 3.2rem;
          height: 3.2rem;
        }
      }
      &:after{
        width: 4rem;
        height: 4rem;
        background: #f45555;
        @media screen and (max-width: $tab){
          width: 2rem;
          height: 2rem;
        }
        @media screen and (max-width: $phone){
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      > *{
        position: relative;
        z-index: 2;
      }
      &__data{
        align-self: center;
        @media screen and (max-width: $phone){
          text-align: center;
        }
        >h2{
          font-size: 2rem;
          font-weight: 700;
          margin: 0 0 .8rem;
          @media screen and (max-width: $tab){
            font-size: 1.32rem;
            margin: 0 0 .4rem;
          }
          @media screen and (max-width: $phone){
            font-size: 1.12rem;
            margin: 0;
          }
        }
        &__logo{
          width: 20rem;
          margin: 0 .6rem 0 0;
          display: inline-block;
          @media screen and (max-width: $tab){
            width: 10.4rem;
          }
          @media screen and (max-width: $phone){
            width: 8.8rem;
            margin: 0 .4rem 0 0;
          }
          img{
            width: 100%;
          }
        }
        > p{
          display: inline-block;
          vertical-align: bottom;
          font-size: 1.24rem;
        }
      }
      &__frame{
        width: 100%;
        padding: calc(100% / 16 * 9) 0 0;
        iframe{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: .4rem;
        }
      }
      &__conner{
        position: absolute;
        right: 0;
        bottom: 0;
        background: #c8f8fe;
        width: 40rem;
        height: 40rem;
        clip-path: polygon(100% 0, 100% 100%, 0 100%);
        z-index: 1;
        @media screen and (max-width: $tab){
          width: 20rem;
          height: 20rem;
        }
        @media screen and (max-width: $phone){
          width: 10rem;
          height: 10rem;
        }
        &:after{
          content: '';
          width: 10rem;
          height: 10rem;
          background: $blue;
          position: absolute;
          right: 0;
          bottom: 0;
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
          @media screen and (max-width: $tab){
            width: 8rem;
            height: 8rem;
          }
          @media screen and (max-width: $phone){
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
		&__context{
			font-size: .96rem;
			text-align: center;
			@media screen and (max-width: $phone){
				margin: 0 $sp-side;
				br{
					display: none;
				}
			}
			&__sml{
				font-size: .8rem;
				display: inline-block;
				margin: 0 0 0 8px;
			}
		}
		&__list{
			margin: 56px 0 0;
			@include flex();
			@media screen and (max-width: $phone){
				margin: 32px 0 0;
				display: block;
			}
			&__item{
				text-align: center;
				width: calc(33.3% - 28px);
				height: auto;
				padding: 40px 28px 32px;
				background: #FFF;
				position: relative;
				@media screen and (max-width: $tab){
					width: calc(33.3% - 14px);	
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 32px 24px;
					margin: 0 0 24px;
					&:last-child{
						margin: 0;
					}
				}
				&:before,
				&:after{
					content: '';
					width: 80%;
					height: 80%;
					border: $blue 1px solid;
					position: absolute;
				}
				&:before{
					top: 0;
					left: 0;
					border-right: 0;
					border-bottom: 0;
				}
				&:after{
					bottom: 0;
					right: 0;
					border-left: 0;
					border-top: 0;
				}
				&__tip{
					color: #FFF;
					background: $blue;
					padding: 12px 16px;
					border-radius: 0 0 8px 0;
					position: absolute;
					top: 0;
					left: 0;
				}
				figure{
					width: 100px;
					border: $blue 1px solid;
					padding: 20px;
					margin: 0 auto 32px;
					border-radius: 50%;
					@media screen and (max-width: $phone){
						margin-bottom: 24px;	
					}
					img{
						width: 100%;
					}
				}
				dl{
					dt{
						font-size: 1.4rem;
						margin: 0 0 8px;
						&:before{
							content: attr(data-eng);
							color: $blue;
							font-size: .8rem;
							margin: 0 0 4px;
							line-height: 1;
							display: block;
						}
					}
					dd{
						font-size: .96rem;
						color: lighten($normal, 8%);
					}
				}
			}
		}
	}
	
	.voice{
		position: relative;
		&__efect{
			content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 33vw 0 33vw calc(33vw * 1.732);
            border-color: transparent transparent transparent $sky;
			position: absolute;
			left: 0;
			bottom: 33vh;
			opacity: .32;
			z-index: -1;
			&.rvs{
				border-width: 33vw calc(33vw * 1.732) 33vw 0;
				border-color: transparent $sky transparent transparent;
				left: auto;
				right: 0;
				bottom: -33vh;
			}
		}
		&__box{
			position: initial;
			&__list{
				padding: 12px 0 32px;
				transition-timing-function: $ease;
				&__item{
					width: 560px;
					height: auto;
					background: #FFF;
					margin: 0 16px;
					border: $blue 1px solid;
					@media screen and (max-width: $phone){
						width: calc(100% - #{$sp-side*2});
						border-radius: 4px;
					}
					a{
						color: $normal;
						padding: 32px;
						position: relative;
						@include flex();
						align-items: center;
						@media screen and (max-width: $phone){
							padding: 16px;
							display: block;
						}
					}
					&__tip{
						font-size: .72rem;
						color: #FFF;
						background: $blue;
						padding: 4px 20px;
						border-radius: 999px;
						position: absolute;
						top: -12px;
						right: 12px;
					}
					&__pic{
						width: 120px;
						height: 120px;
						overflow: hidden;
						border-radius: 50%;
						@media screen and (max-width: $phone){
							margin: 0 auto;
						}
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					&__data{
						width: calc(100% - 120px);
						line-height: 1.56;
						padding: 0 0 0 24px;
						@media screen and (max-width: $phone){
							width: auto;
							padding: 12px 0 0;
							text-align: center;
						}
                        .sml{
                            font-size: .88rem;
                            display: inline-block;
                        }
						dl{
							dt{
								font-size: 1.1rem;
								font-weight: 700;
								.sml{
									margin: 0 0 0 8px;
								}
							}
							dd{
								font-size: 1.2rem;
								.sml{
									margin: 0 8px 0 0;
								}
							}
						}
						h3{
							font-size: 1.12rem;
							color: darken($blue, 4%);
							margin: 8px 0 0;
							@media screen and (max-width: $phone){
								font-size: 1.08rem;	
							}
						}
					}
				}
			}
			&__pager{
				@include flex();
				align-items: center;
				justify-content: flex-start;
				margin: 0 0 0 $pc-side;
				@media screen and (max-width: $phone){
					margin-left: $sp-side;	
				}
				.swiper-pagination-bullet{
					width: 40px;
					height: 40px;
					background: darken($gray, 2%);
					margin: 0 2px 0 0;
					border-radius: 0;
					@include flex();
					align-items: center;
					justify-content: center;
					opacity: 1;
					&.swiper-pagination-bullet-active{
						color: #FFF;
						background: $blue;
						transform: scale(1);
					}
				}
			}
		}
	}
	
	.report{
		position: relative;
		z-index: 2;
		@media screen and (max-width: $tab){
			padding-bottom: 0 !important;	
		}
		&__subtitle{
			color: $blue;
			@include flexfont(13.3rem, 16);
			font-weight: 700;
			line-height: 1;
			position: absolute;
			top: -12.5vh;
			right: $pc-side;
			z-index: -1;
			@media screen and (max-width: $tab){
				@include flexfont(10rem, 12);
				top: -10vh;
				right: 40px;
			}
			@media screen and (max-width: $phone){
				@include flexfont(5.6rem, 8);
				top: -4.4vh;
				right: $sp-side;
			}
		}
		&__box{
			@include flex();
			align-items: center;
			@media screen and (max-width: $phone){
				display: block;
				padding-right: 0 !important;
			}
			&__pic{
				width: 58%;
				@media screen and (max-width: $tab){
					width: 52%;	
				}
				@media screen and (max-width: $phone){
					width: 92%;
					margin: 4.4vh 0 0;
				}
				img{
					width: 100%;
				}
			}
			&__data{
				width: 42%;
				max-width: 620px;
				padding: 64px 40px;
				padding-bottom: 0;
				@media screen and (max-width: $tab){
					width: 48%;
					padding: 56px 32px;
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 32px $sp-side*2 0;	
				}
			}
		}
		&__links{
			margin: 80px 0 0;
			padding: 48px 0;
			position: relative;
			@media screen and (max-width: $tab){
				margin: 0;
			}
			@media screen and (max-width: $phone){
				margin: 24px 0 0;
				padding: 0 $sp-side;	
			}
			&:before{
				content: '';
				width: 64%;
				height: 100%;
				background: $gray;
				position: absolute;
				top: 0;
				right: 0;
				z-index: -1;
				@media screen and (max-width: $phone){
					display: none;
				}
			}
			&__box{
				padding: 24px 32px;
				background: #FFF;
				border: $gray 8px solid;
				border-radius: 4px;
				@include flex();
				justify-content: flex-start;
				align-items: center;
				@media screen and (max-width: $phone){
					padding: $sp-side;
					display: block;	
				}
				dt{
					font-size: 1.08rem;
					font-weight: 700;
					border-right: darken($border, 4%) 2px solid;
					margin: 0 24px 0 0;
					padding: 0 24px 0 0;
          @media screen and (max-width: $tab){
            width: 100%;
            margin: 0 0 .8rem;
            border: none;
          }
					@media screen and (max-width: $phone){
						border: none;
						margin: 0;
						padding: 0;
						text-align: center;
					}
				}
				dd{
					font-size: .88rem;
					line-height: 1.56;
					margin: 0 0 0 24px;
					@media screen and (max-width: $tab){
						font-size: .92rem;	
					}
					@media screen and (max-width: $phone){
						margin: 0;	
					}
					&:first-of-type{
						margin: 0;
					}
					@media screen and (max-width: $tab){
						&:nth-last-of-type(2){
							width: 100%;
							margin: 8px 0 0;
							br{
								display: none;
							}
						}	
					}
					&.alt{
						width: 100%;
						margin: 16px 0 0;
						padding: 16px 0 0;
						border-top: $border 1px solid;
					}
          &.cosoji{
            font-size: 1.12rem;
            width: 100%;
            margin: 1.6rem 0 0;
            @include flex();
            align-items: flex-end;
            justify-content: flex-start;
            @media screen and (max-width: $tab){
              font-size: 1rem;
            }
            figure{
              width: 10rem;
              margin: 0 .8rem;
              @media screen and (max-width: $phone){
                margin: .64rem .64rem 0 0;
                width: 52%;
              }
            }
          }
					figure{
						width: 200px;
						@media screen and (max-width: $tab){
							width: 240px;	
						}
						@media screen and (max-width: $phone){
							width: 180px;
							margin: 16px auto;
						}
						img{
							width: 100%;
						}
					}
				}
			}
		}
		.datasets{
			&__title{
				@include flexfont(1.66rem, 16);
				line-height: 1.56;
				font-weight: 700;
				margin: 0 0 24px -2px;
				@media screen and (max-width: $phone){
					margin: 0 0 16px;
				}
				&:before{
					content: attr(data-eng);
					@include flexfont(.8rem, 2);
					letter-spacing: .12em;
					color: $blue;
					line-height: 1;
					margin: 0 0 4px 4px;
					display: block;
				}
			}
			&__description{
				dt{
					font-size: 1.2rem;
					line-height: 1.66;
					font-weight: 700;
				}
				dd{
					margin: calc(12px + .6vw) 0 0;
					text-align: justify;
					&.emphasis{
						font-size: 1.06rem;
						color: $blue;
						font-weight: 700;
						border: $blue 2px solid;
						padding: 16px 24px;
					}
				}
			}
		}
	}
	
	
	.features{
		$phoneSize: 260px;
		$phoneHeight: 550px;
		position: relative;
		z-index: 1;
		&__efect{
			content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 33vw 0 33vw calc(33vw * 1.732);
            border-color: transparent transparent transparent $sky;
			position: absolute;
			left: 0;
			top: -33.3vh;
			opacity: .32;
			z-index: -1;
		}
		&__title{
			font-size: 3rem;
			font-weight: 700;
			line-height: 1;
			text-align: center;
		}
		&__box{
			position: relative;
			&__pic{
				width: 100%;
				height: 100vh;
				min-height: 600px;
				position: absolute;
				top: 0;
				left: 0;
				@include flex();
				align-items: center;
				justify-content: flex-start;
				overflow: hidden;
				@media screen and (max-width: $tab){
					display: none;
				}
				&__draw{
					width: $phoneSize;
					height: $phoneHeight;
                    position: absolute;
                    top: calc(50% + 40px);
                    left: calc(50% - #{$phoneSize/2});
					z-index: 5;
                    transform: translate(-50%, -50%);
					transition: left 400ms $ease;
					&#drawImgClone{
						z-index: 4;
					}
					&.move-5{
						left: calc(50% - #{$phoneSize*1.5} - 40px);
					}
					&:after{
						content: '';
						width: 100%;
						height: 100%;
						background: url(../../images/index/phone_mockup_2x.png) no-repeat center center / cover;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 10;
					}
					img{
						width: 100%;
						height: 100%;
						padding: 4px;
						object-fit: cover;
						position: absolute;
						top: 0;
						left: 0;
						border-radius: 32px;
						opacity: 0;
						pointer-events: none;
						transition: opacity 400ms $ease;
						&:first-of-type{
							opacity: 1;
						}
						&.active{
							opacity: 1;
						}
					}
				}
				&__eng{
					color: $blue;
					@include flexfont(8.8rem, 15);
					margin: 10vh 0 0 $pc-side;
					font-weight: 700;
					white-space: nowrap;
					line-height: 1;
				}
			}
			&__list{
				padding: calc(40px + 6.6vh) 0 0 50%;
				margin: calc(40px + 6.6vh) 0 0;
				@include flex();
				align-items: center;
				position: relative;
				@media screen and (max-width: $tab){
					padding: 56px 0 0;
					margin: 56px 0 0;
					border-top: $border 1px solid;
				}
				@media screen and (max-width: $phone){
					display: block;	
				}
				&:before{
					content: '';
					width: calc(50% - 80px);
					height: 1px;
					background: $border;
					position: absolute;
					top: 0;
					right: 0;
					@media screen and (max-width: $tab){
						display: none;
					}
				}
				&:first-of-type{
					padding-top: 25vh;
					margin: 0;
					@media screen and (max-width: $tab){
						padding-top: 120px;
						margin: 0;
						border-top: none;
					}
					@media screen and (max-width: $phone){
						padding-top: 40px;	
					}
					&:before{
						display: none;
					}
				}
				&__sp{
					display: none;
					@media screen and (max-width: $tab){
						width: $phoneSize*.8;
						display: block;
						position: relative;
						@media screen and (max-width: $phone){
							width: auto;
							@include flex();
							justify-content: center;
						}
						> figure{
							height: $phoneHeight*.8;
							position: relative;
							@media screen and (max-width: $phone){
								width: $phoneSize*.56;
								height: $phoneHeight*.56;
							}
							&:nth-of-type(2){
								position: absolute;
								top: 0;
								left: 0;
								transform: translate(20px, 60px);
								z-index: 3;
								@media screen and (max-width: $phone){
									position: relative;
									margin: 0 0 0 24px;
									transform: translate(0);
								}
							}
							img{
								width: 100%;
								height: 100%;
								object-position: center;
								object-fit: cover;
								border-radius: 30px;
								@media screen and (max-width: $phone){
									border-radius: 20px;	
								}
							}
							&:after{
								content: '';
								width: 100%;
								height: 100%;
								background: url(../../images/index/phone_mockup_2x.png) no-repeat center center / cover;
								position: absolute;
								top: 0;
								left: 0;
								z-index: 2;
							}
						}
					}
				}
				&__item{
					@media screen and (max-width: $tab){
						width: calc(100% - #{$phoneSize*.8});
					}
					@media screen and (max-width: $phone){
						width: auto;	
					}
				}
			}
		}
		.listdata{
			padding: 0 0 0 80px;
			@media screen and (max-width: $tab){
				padding: 0 0 0 48px;
			}
			@media screen and (max-width: $phone){
				padding: 24px 0 0;	
			}
			&__data{
				position: relative;
				dt{
					font-size: 2.2rem;
					line-height: 1.56;
					font-weight: 700;
					margin: 0 0 16px;
					@media screen and (max-width: $phone){
						font-size: 1.66rem;	
						text-align: center;
					}
					&:before{
						content: attr(data-eng);
						@include flexfont(.72rem, 2);
						letter-spacing: .12em;
						color: $blue;
						line-height: 1;
						margin: 0 0 4px 4px;
						display: block;
						@media screen and (max-width: $phone){
							margin: 0 0 3px;	
						}
					}
				}
				dd{
					font-size: 1.16rem;
					line-height: 1.66;
					font-weight: 700;
					margin: 24px 0 0;
					@media screen and (max-width: $phone){
						font-size: 1.08rem;
						text-align: center;
						margin: 16px 0 0;
					}
					&:first-of-type{
						margin-top: 0;
					}
					&.emphasis{
						font-size: 1.12rem;
						color: $blue;
						border: $blue 2px solid;
						padding: 12px 16px;
						border-radius: 4px;
						@media screen and (max-width: $phone){
							padding: 8px 15px;
							font-size: 1.04rem;
						}
					}
				}
			}
			&__description{
				font-size: .92rem;
				text-align: justify;
				margin: 24px 0 0;
				@media screen and (max-width: $tab){
					font-size: 1rem;	
				}
			}
		}
	}
	
	.advantages{
		position: relative;
		z-index: 10;
		&__efect{
			content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 33vw calc(33vw * 1.732) 33vw 0;
            border-color: transparent $sky transparent transparent;
			position: absolute;
			right: 0;
			bottom: 33vh;
			opacity: .32;
			z-index: -1;
		}
		&__box{
			background: #FFF;
			padding: 32px;
			margin: 0 0 24px;
			border-radius: 16px;
			box-shadow: 0 4px 24px rgba($normal, .06);
			@include flex();
			@media screen and (max-width: $phone){
				padding: $sp-side;
				padding-top: $sp-side*2;
				border-radius: 4px;
				display: block;
			}
			figure{
				width: 25%;
				@media screen and (max-width: $phone){
					width: 40%;
					margin: 0 auto;
				}
				img{
					width: 100%;
				}
			}
			dl{
				font-size: 1.08rem;
				width: 75%;
				padding: 0 0 0 40px;
				position: relative;
				@media screen and (max-width: $phone){
					width: auto;
					padding: 16px 0 0;
				}
				dt{
					font-size: 2.4rem;
					color: $blue;
					font-weight: 700;
					@media screen and (max-width: $phone){
						font-size: 1.44rem;
						text-align: center;
					}
					.sml{
						font-size: .92rem;
						margin: 0 0 0 8px;
						display: inline-block;
					}
				}
				dd{
					&.link{
						position: absolute;
						right: 0;
						bottom: 0;
						text-decoration: underline;
						@media screen and (max-width: $phone){
							position: relative;
							margin: 8px 0 0;
							text-align: right;
							i{
								display: inline-block;
								margin: 0 4px 0 0;
							}
						}
					}
				}
			}
		}
		&__list,
		&__minilist{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
			&__item{
				width: calc(25% - 12px);
				background: #FFF;
				padding: 24px;
				border-radius: 16px;
				box-shadow: 0 4px 24px rgba($normal, .06);
				@media screen and (max-width: $phone){
					width: auto;
					padding: $sp-side;
					padding-top: $sp-side*2;
					border-radius: 4px;
					@include flex();
					align-items: center;
					margin: 0 0 20px;
					&:last-child{
						margin: 0;
					}
				}
				figure{
					width: 64%;
					margin: 0 auto 12px;
					@media screen and (max-width: $phone){
						width: 80px;
						margin: 0;
					}
					img{
						width: 100%;
					}
				}
				dl{
					line-height: 1.66;
					@media screen and (max-width: $phone){
						width: calc(100% - 80px);
						padding: 0 0 0 24px;
					}
					dt{
						font-size: 1.08rem;
						color: $blue;
						font-weight: 700;
						text-align: center;
						margin: 0 0 10px;
						@media screen and (max-width: $phone){
							text-align: left;	
						}
					}
					dd{
						font-size: .92rem;
						text-align: justify;
						@media screen and (max-width: $phone){
							line-height: 1.66;	
						}
					}
				}
			}
		}
		&__minilist{
			&__item{
				width: calc(50% - 12px);
				margin: 24px 0 0;
				@include flex();
				align-items: center;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 20px;
					&:last-child{
						margin: 0;
					}
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
				figure{
					width: 25%;
					margin: 0;
					img{
						width: 100%;
					}
				}
				dl{
					width: 75%;
					padding: 0 0 0 24px;
					dt{
						text-align: left;
						margin: 0 0 6px;
					}
				}
			}
		}
	}
	
	.news{
        position: relative;
        z-index: 2;
		color: $normal;
		&.wrap{
			padding-top: 80px;
			padding-bottom: 80px;
		}
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width: $phone){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width: $phone){
				width: auto;
				@include flex();
				align-items: flex-end;
			}
			h3{
				font-size: 1rem;
				font-weight: 700;
				line-height: 1;
				margin: 0 0 12px;
				&:before{
					content: attr(data-eng);
					font-size: 2.4rem;
					font-weight: 700;
					margin: 0 24px 0 0;
					display: inline-block;
					transform: translateX(-2px);
					@media screen and (max-width: $phone){
						font-size: 1.88rem;	
					}
				}
			}
			a{
				font-size: 1.08rem;
				font-weight: 700;
				color: $normal;
				display: inline-block;
				&:hover{
					text-decoration: underline;
				}
				i{
					font-size: .96rem;
					margin: 0 0 0 12px;
					display: inline-block;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 132px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width: $phone){
				width: auto;
				height: 160px;
				padding: 0;
				margin: 12px 0 0;
			}
            li{
                padding: 0 0 24px;
                margin: 0 0 24px;
				border-bottom: rgba($normal,.08) 1px solid;
				@media screen and (max-width: $phone){
					padding: 0 0 16px;
					margin: 0 0 16px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: $normal;
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                span.time{
                    width: 100px;
                    font-size: .72rem;
                    background: $normal;
                    color: #FFF;
                    padding: 4px 0;
                    text-align: center;
                }
                h4{
                    width: calc(100% - 100px);
                    font-size: 1.04rem;
                    padding: 0 0 0 32px;
                }
            }
        }
	}
		
	.kussion{
		position: relative;
		overflow: hidden;
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			&:before,
			&:after{
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				transform: scale(1.2);
				@media screen and (max-width: $phone){
					transform: scale(2);
				}
			}
			&:before{
				background: url(../../images/common/bg_kussion_2x.jpg) no-repeat center / cover;
			}
			&:after{
				background: darken($normal, 8%);
				opacity: .64;
			}
		}
		&__data{
			color: rgba(#FFF,.88);
			text-align: center;
			position: relative;
			z-index: 2;
			@media screen and (max-width: $phone){
				padding: 0 $sp-side*2;	
			}
			h3{
				font-size: 1.12rem;
				font-weight: 700;
				line-height: 1;
				margin: 0 0 24px;
				&:before{
					content: attr(data-eng);
					font-size: 2.96rem;
					margin: 0 0 12px;
					display: block;
				}
			}
			.context{
				font-size: 1.08rem;
			}
		}
	}
}