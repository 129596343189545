#site-footer{
	background: darken($normal, 8%);
	color: rgba(#FFF,.88);
	position: relative;
	z-index: 3;
	a{
		color: rgba(#FFF,.88);
	}
	.pagetop{
		background: darken($gray, 4%);
		color: lighten($normal, 12%);
		display: block;
		padding: 20px 0;
		text-align: center;
		@media screen and (max-width: $phone){
			padding: 15px 0;	
		}
	}
	.footwrapper{
		padding: 88px 0;
		@media screen and (max-width: $phone){
			padding: $sp-side * 2 0;	
		}
		.company{
			@include flex();
			justify-content: flex-start;
			align-items: center;
			position: relative;
			@media screen and (max-width: $phone){
				display: block;	
			}
			&__logo{
				margin: 0 32px 0 0;
				@media screen and (max-width: $phone){
					margin: 0 0 16px;	
				}
				svg{
					width: 240px;
					display: block;
					@media screen and (max-width: $phone){
						margin: 0 auto;
					}
					rect,
					circle,
					path{
						fill: $base;
					}
				}
				&__context{
					font-size: .88rem;
					margin: 6px 0 0;
					opacity: .48;
					@media screen and (max-width: $phone){
						text-align: center;	
					}
				}
			}
			&__data{
				font-size: .88rem;
				line-height: 1.66;
			}
			&__contact{
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				@media screen and (max-width: $phone){
					margin: 20px 0 0;
					position: relative;
					top: 0;
					transform: translate(0);
					@include flex();
					align-items: center;
				}
				li{
					margin: 0 0 10px 0;
					@media screen and (max-width: $phone){
						width: calc(50% - #{$sp-side/2});
						margin: 0;
					}
					&:last-child{
						margin-right: 0;
					}
					a{
						@include btn();
						font-size: .92rem;
						background: none;
						padding: 6px 32px;
						border: rgba(#FFF, .12) 1px solid;
						@media screen and (max-width: $phone){
							text-align: center;	
						}
					}
				}	
			}
		}
		.fnav{
			margin: 48px 0 0;
			padding: 48px 0 0;
			border-top: rgba(#FFF,.06) 1px solid;
			@media screen and (max-width: $phone){
				margin: 24px 0 0;
				padding: 24px 0 0;
			}
			&__list{
				@include flex();
				justify-content: center;
				@media screen and (max-width: $tab){
					justify-content: flex-start;
					margin: 0 0 0 -24px;
				}
				@media screen and (max-width: $phone){
					display: block;
					margin: 0;
					border: rgba(#FFF,.12) 1px solid;
					border-radius: 4px;
				}
				li{
					line-height: 1;
					margin: 0 32px 0 0;
					padding: 0 32px 0 0;
					position: relative;
					@media screen and (max-width: $tab){
						width: 33.3%;
						margin: 0 0 16px;
						padding: 0 0 0 24px;
					}
					@media screen and (max-width: $phone){
						width: auto;
						margin: 0;
						padding: 0;
						border-bottom: rgba(#FFF,.12) 1px solid;
						&:last-child{
							border-bottom: none;
						}
					}
					&:after{
						content: '';
						background: #FFF;
						width: 1px;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						opacity: .12;
						@media screen and (max-width: $tab){
							display: none;
						}
					}
					&:last-child{
						&:after{
							display: none;
						}
					}
					a{
						font-size: .92rem;
						@media screen and (max-width: $tab){
							height: 100%;
							border: rgba(#FFF,.12) 1px solid;
							line-height: 1.56;
							padding: 12px 16px;
							text-align: center;
							@include flex();
							align-items: center;
							justify-content: center;
						}
						@media screen and (max-width: $phone){
							border: none;
							text-align: left;
							display: block;
						}
					}
				}
			}
		}
	}
	.copy{
		display: block;
		padding: 24px 0;
		font-size: .72rem;
		letter-spacing: .16em;
		text-transform: uppercase;
		opacity: .64;
		text-align: center;
		background: rgba(#FFF,.04);
	}
}