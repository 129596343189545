.site-voice{
	.voicemain{
		&__box{
			position: relative;
			background: darken($gray, 2%) url(../../images/common/second_main_bg_2x.png) no-repeat center / cover;
			margin: 0 0 72px;
			@include flex();
			align-items: center;
			z-index: 2;
			@media screen and (max-width: $phone){
				background: darken($gray, 2%);
				padding: 24px $sp-side $sp-side;
				margin: 0 0 32px;
				border-radius: 4px;
				display: block;
			}
			&__pic{
				width: 50%;
				padding-top: 35%;
				position: relative;
				@media screen and (max-width: $phone){
					width: 100px;
					height: 100px;
					padding: 0;
					margin: 0 auto;
					border-radius: 50%;
					overflow: hidden;
				}
				img{
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					object-fit: cover;
					object-position: center;
					transform: translate(-50%, -50%);
				}
			}
			&__data{
				width: 50%;
				background: #FFF;
				padding: 48px 56px;
				border-radius: 2px;
				transform: translateX(-56px);
				box-shadow: 0 0 32px rgba($blue, .05);
				@media screen and (max-width: $phone){
					width: auto;
					padding: $sp-side;
					margin: 24px 0 0;
					border-radius: 3px;
					transform: translate(0);
				}
				&__title{
					font-size: 1.44rem;
					font-weight: 700;
					line-height: 1;
				}
				&__catch{
					font-size: 1.44rem;
					color: $blue;
					font-weight: 700;
					line-height: 1.56;
					margin: 0 0 24px;
					@media screen and (max-width: $phone){
						font-size: 1.24rem;	
					}
				}
				dl{
					margin: 16px 0 0;
					padding: 12px 0 0;
					border-top: $border 1px solid;
					@include flex();
					align-items: flex-end;
					justify-content: flex-start;
					position: relative;
					@media screen and (max-width: $phone){
						display: block;	
					}
					dt{
						margin: 0 20px 0 0;
						@media screen and (max-width: $phone){
							margin: 0 0 4px;	
						}
					}
					dd{
						font-weight: 700;
						strong{
							font-size: 1.44rem;
							line-height: 1;
							display: inline-block;
							margin: 0 6px 0 0;
						}
					}
				}
			}
		}
	}
	
	.voicelist{
		li{
			margin: 0 0 48px;
			padding: 0 0 48px;
			border-bottom: $border 1px solid;
			@include flex();
			@media screen and (max-width: $phone){
				margin: 0 0 24px;
				padding: 0 0 24px;
				display: block;
			}
			&:last-child{
				margin: 0;
				padding: 0;
				border: none;
			}
			figure{
				width: 40%;
				padding: 0 40px 0 0;
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 16px;
				}
				+ dl{
					width: 60%;
					@media screen and (max-width: $phone){
						width: auto;	
					}
				}
				img{
					width: 100%;
				}
			}
			dl{
				dt{
					color: $blue;
					font-size: 1.32rem;
					line-height: 1.56;
					font-weight: 700;
					margin: 0 0 16px;
				}
				dd{
					font-size: .96rem;
					line-height: 1.96;
					text-align: justify;
				}
			}
		}
	}
	
	.owners{
		&__list{
			@include flex();
			margin: 0 0 0 -24px;
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;
			}
			li{
				width: 50%;
				margin: 24px 0 0;
				padding: 0 0 0 24px;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 20px;
					padding: 0;
					&:last-child{
						margin: 0;
					}
				}
				&:nth-child(-n+2){
					margin-top: 0;
				}
				a{
					color: $normal;
					background: #FFF;
					border: #FFF 2px solid;
					padding: 24px;
					@include flex();
					@media screen and (max-width: $phone){
						padding: $sp-side;	
					}
					&:hover{
						border-color: $blue;
						img{
							opacity: 1;
						}
					}
					> figure{
						width: 30%;
						@media screen and (max-width: $phone){
							width: 24%;	
						}
						img{
							width: 100%;
						}
					}
					> .data{
						width: 70%;
						padding: 0 0 0 32px;
						@media screen and (max-width: $phone){
							width: 76%;
							padding: 0 0 0 20px;
						}
						dl{
							dt{
								font-weight: 700;
								.sml{
									font-size: .92rem;
								}
							}
							dd{
								.sml{
									font-size: .88rem;
								}
							}
						}
						h3{
							font-size: 1.08rem;
							color: $blue;
							margin: 12px 0 0;
							line-height: 1.56;
						}
					}
				}
			}

		}
	}
}