@import "module/module";
@import "module/reset";
@import "plugin/swiper";

:root{
	font-size: 15px;
	@media screen and (max-width: $tab){
		font-size: 14px;	
	}
	@media screen and (max-width: $phone){
		font-size: 13.5px;
	}
}

@media screen and (min-width: $width){
	@include default(
		$width,   //サイト幅
		1rem,     //フォントサイズ
		$normal,  //フォントカラー
		$link,    //リンクカラー
		#FFF,     //ボディ背景
		$pc-side  //左右パディング
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto,     //サイト幅
		1rem,     //フォントサイズ
		$normal,  //フォントカラー
		$link,    //リンクカラー
		#FFF,     //ボディ背景
		$pc-side  //左右パディング
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto,   //サイト幅
		1rem,     //フォントサイズ
		$normal,  //フォントカラー
		$link,    //リンクカラー
		#FFF,     //ボディ背景
		$sp-side  //左右パディング
	);
}

@import "common/header";
@import "common/footer";
@import "common/breadcrumb";
@import "page/firstview";
@import "page/index";
@import "page/feature";
@import "page/price";
@import "page/flow";
@import "page/voice";
@import "page/news";
@import "page/company";
@import "page/privacy";
@import "page/contact";
@import "page/edit";


.site-common{
	&__message{
		font-size: 1.92rem;
		font-weight: 700;
		line-height: 1.56;
		text-align: center;
		margin: 0 0 24px;
		@media screen and (max-width: $phone){
			font-size: 1.16rem;
			margin: 0 $sp-side 20px;
		}
		&:before{
			content: attr(data-eng);
			font-size: .92rem;
			font-weight: 500;
			color: $blue;
			letter-spacing: .08em;
			margin: 0 0 12px;
			display: block;
		}
	}
	&__title{
		font-size: 2.32rem;
		font-weight: 700;
		line-height: 1.56;
		text-align: center;
		margin: 0 0 32px;
		@media screen and (max-width: $phone){
			font-size: 1.88rem;
			margin: 0 $sp-side 24px;
		}
		&:before{
			content: attr(data-eng);
			font-size: .92rem;
			font-weight: 500;
			color: $blue;
			letter-spacing: .08em;
			margin: 0 0 4px;
			display: block;
			@media screen and (max-width: $phone){
				margin: 0 0 2px;	
			}
		}
	}
	&__midtitle{
        font-size: 1.48rem;
        font-weight: 700;
        line-height: 1;
        padding: 12px;
        padding-left: 20px;
        margin: 0 0 24px;
        border-bottom: rgba($normal, .08) 1px solid;
        position: relative;
		@media screen and (max-width: $phone){
			font-size: 1.32rem;
			margin: 0 0 16px;	
		}
        &:before{
            content: '';
            width: 6px;
            height: 70%;
            background: $blue;
            border-radius: 4px;
            display: block;
            position: absolute;
            top: 15%;
            left: 0;
        }
	}
	&__pagemain{
		padding: 104px 40px 32px;
		border-bottom: rgba($blue, .08) 1px solid;
		@include flex();
		align-items: center;
		justify-content: center;
		overflow: hidden;
		position: relative;
		z-index: 1;
		@media screen and (max-width: $phone){
			padding: 96px $sp-side 32px;
		}
		&__pic{
			width: 600px;
			padding: 0 0 0 48px;
			order: 2;
			position: relative;
			transform: translateY(-8px);
			z-index: 2;
			@media screen and (max-width: $tab){
				width: 60%;	
			}
			@media screen and (max-width: $phone){
				width: 88%;
				padding: 0;
				margin: 0 auto;
				transform: translate(0);
				order: 2;
			}
			img{
				width: 100%;
			}
		}
		&__data{
			width: 400px;
			position: relative;
			z-index: 3;
			@media screen and (max-width: $tab){
				width: 40%;
			}
			@media screen and (max-width: $phone){
				width: 100%;
				padding: 0 $sp-side 32px;
				text-align: center;
				order: 1;
			}
			&__title{
				@include flexfont(2rem, 10);
				line-height: 1.36;
				white-space: nowrap;
				color: $blue;
				margin: 0 0 16px;
				font-weight: 700;
				&:before{
					content: attr(data-sub);
					font-size: 1.32rem;
					line-height: 1;
					margin: 0 0 2px;
					display: block;
					@media screen and (max-width: $phone){
						font-size: 1.56rem;
						line-height: 1.56;
					}
				}
				@media screen and (max-width: $tab){
					@include flexfont(1.56rem, 16);
					margin: 0 0 12px;
				}
				@media screen and (max-width: $phone){
					font-size: 1.56rem;	
				}
			}
			&__txt{
				@include flexfont(.8rem, 4);
				transform: translateX(2px);
				strong{
					color: $blue;
				}
				@media screen and (max-width: $tab){
					@include flexfont(.92rem, 4);
					line-height: 1.66;
					transform: translate(0);
					br{
						display: none;
					}
				}
				@media screen and (max-width: $phone){
					font-size: 1rem;	
				}
			}
			&__btn{
				margin: 32px 0 0;
				transform: translateX(-4px);
				@media screen and (max-width: $tab){
					margin: 20px 0 0;
					transform: translate(-4px);
				}
				a{
					@include btn();
					@media screen and (max-width: $tab){
						font-size: 1rem;
						display: inline-block;
						padding: 8px 32px;
					}
				}
			}
		}
		&__bg{
			background: url(../../images/common/second_main_bg_b_2x.png) no-repeat center / cover;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			@media screen and (max-width: $tab){
				background-position: right bottom;
			}
		}
	}
	&__btnarea{
		text-align: center;
		a{
			@include btn();
		}
	}
	&__bottom{
        background: $gray;
        padding: 32px;
		border-radius: 3px;
		text-align: center;
		@media screen and (max-width: $phone){
			padding: $sp-side;	
		}
		&__box{
			dt{
				font-size: 1.88rem;
				font-weight: 700;
				@media screen and (max-width: $phone){
					font-size: 1.44rem;	
				}
			}
			dd{
				font-size: 1.12rem;
				@media screen and (max-width: $phone){
					font-size: 1.08rem;	
				}
			}
			&__contact{
				margin: 24px 0 0;
				@media screen and (max-width: $phone){
					margin: 12px 0 0;	
				}
				a{
					@include btn();
					margin: 0 8px 0 0;
					@media screen and (max-width: $phone){
						margin: 8px 0 0;
					}
				}
			}
		}
	}
	&__table{
		width: 100%;
		background: #FFF;
		border: $border 1px solid;
		border-collapse: collapse;
		@media screen and (max-width: $phone){
			display: block;	
		}
		tbody{
			@media screen and (max-width: $phone){
				display: block;	
			}
			tr{
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					display: block;
					&:last-child{
						border-bottom: none;
					}
				}
				th,td{
					font-size: 1.02rem;
					padding: 20px 24px;
					text-align: left;
					vertical-align: middle;
					@media screen and (max-width: $phone){
						display: block;
						padding: $sp-side;
					}
				}
				th{
					width: 22.5%;
					background: $gray;
					@media screen and (max-width: $phone){
						width: auto;
						background: darken($gray, 3%);
					}
				}
				td{
					padding-left: 40px;
					@media screen and (max-width: $phone){
						padding-left: $sp-side;	
					}
					.smlicon{
						font-size: .8rem;
						margin: 0 0 0 6px;
						display: inline-block;
						transform: translateY(-2px);
					}
				}
			}
		}
	}
}

.bg-white{
	background: #FFF;
}

.bg-gray{
	background: $gray;
}

.t-left{
	text-align: left;
}
.t-right{
	text-align: right;
}
.t-center{
	text-align: center;
}

@for $i from 1 through 10{
    .pa-t-#{$i}{
        padding-top: calc(2vh * #{$i} + 16px);
		@media screen and (max-width: $tab){
			padding-top: calc(1.6vh * #{$i} + 12px);
		}
		@media screen and (max-width: $phone){
			padding-top: calc(1.4vmin * #{$i} + 16px);
		}
    }
    .pa-b-#{$i}{
        padding-bottom: calc(2vh * #{$i} + 16px);
		@media screen and (max-width: $tab){
			padding-bottom: calc(1.6vh * #{$i} + 12px);
		}
		@media screen and (max-width: $phone){
			padding-bottom: calc(1.4vmin * #{$i} + 16px);
		}
    }
    .pa-l-#{$i}{
        padding-left: 8px * $i;
    }
    .pa-r-#{$i}{
        padding-right: 8px * $i;
    }
    .ma-t-#{$i}{
        margin-top: calc(2vh * #{$i} + 16px);
		@media screen and (max-width: $tab){
			margin-top: calc(1.6vh * #{$i} + 12px);
		}
		@media screen and (max-width: $phone){
			margin-top: calc(1.4vmin * #{$i} + 16px);
		}
    }
    .ma-b-#{$i}{
        margin-bottom: calc(2vh * #{$i} + 16px);
		@media screen and (max-width: $tab){
			margin-bottom: calc(1.6vh * #{$i} + 12px);
		}
		@media screen and (max-width: $phone){
			margin-bottom: calc(1.4vmin * #{$i} + 16px);
		}
    }
    .ma-l-#{$i}{
        margin-left: 8px * $i;
    }
    .ma-r-#{$i}{
        margin-right: 8px * $i;
    }
}

.marker{
	font-weight: 700;
	background: linear-gradient(transparent 70%, $yellow 70%);
}


.scrollAnime{
	&__target{
		opacity: 0;
		transform: translateY(40px);
	}
	&._active{
		> .scrollAnime__target{
			opacity: 1;
			transform: translateY(0);
			transition: all 500ms $ease;
			transition-property: transform, opacity;
			@for $i from 1 through 20{
				&:nth-of-type(#{$i}){
					transition-delay: $i*120ms;
				}
			}
		}
	}
}


#fixBnr{
	position: fixed;
	right: 0;
	bottom: 0;
	box-shadow: 0 0 0 rgba($normal, 0);
	transform: translateY(100%);
	z-index: 1000;
	transition: all 500ms $ease;
	border-radius: 13px 0 0 0;
	transition-property: transform, box-shadow;
	&.active{
		transform: translate(0);
		box-shadow: 0 0 32px rgba($normal, .24);
		.close{
			transform: scale(1);
		}
	}
	.close{
		width: 40px;
		height: 40px;
		color: #FFF;
		background: $blue;
		border-radius: 50%;
		position: absolute;
		top: -20px;
		right: 8px;
		@include flex();
		align-items: center;
		justify-content: center;
		transform: scale(0);
		cursor: pointer;
		z-index: 2;
		transition: transform 300ms $ease;
		@media screen and (max-width: $tab){
			width: 32px;
			height: 32px;
			top: -16px;
			right: 4px;
		}
	}
	a{
		border-radius: 13px 0 0 0;
		overflow: hidden;
		display: block;
		@media screen and (max-width: $phone){
			border-radius: 6px 0 0 0;	
		}
		img{
			width: 260px;
			@media screen and (max-width: $tab){
				width: 200px;	
			}
			@media screen and (max-width: $phone){
				width: 120px;
			}
		}
	}
}

#follower{
	position: fixed;
	top: 0;
	left: 0;
	width: 80px;
	height: 80px;
	z-index: 1000;
	background: $blue;
	border-radius: 50%;
	pointer-events: none;
	opacity: 0;
	transform: scale(0);
	transition: opacity 300ms 150ms $ease, transform 300ms 150ms $ease;
	@media screen and (max-width: $tab){
		display: none !important;
	}
	i{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(../../images/common/slide_ui_2x.png) no-repeat center;
		background-size: 60px;
		transform: scale(0);
		transition: transform 300ms $ease;
	}
	&.active{
		opacity: 1;
		transform: scale(1);
		transition: opacity 300ms $ease, transform 300ms $ease;
		i{
			transform: scale(1);
			transition: transform 300ms 150ms $ease;
		}
	}
}


.fadeto-enter-active, .fadeto-leave-active {
	transition: transform 400ms $ease , opacity 400ms $ease;
}
.fadeto-enter, .fadeto-leave-to {
	transform: translateX(100%);
	opacity: 0;
}

.grecaptcha-badge{
	bottom: 200px !important;
	z-index: 9999;
	@media screen and (max-width: $phone){
		bottom: 100px !important;
	}
}

