.site-edit{
    font-size: 1rem;
    line-height: 1.8;
	background: #FFF;
	border: darken($gray, 2%) 8px solid;
	padding: 32px;
	@media screen and (max-width: $phone){
		font-size: 1rem;
		border: none;
		padding: 0;
	}
    h3,h4{
        font-size: 1.32rem;
        border-bottom: $border 2px solid;
        font-weight: 700;
        padding: 0 0 0 16px;
        margin: 0 0 16px;
        position: relative;
        &:before{
            content: '';
            width: 4px;
            height: 80%;
            position: absolute;
            top: 10%;
            left: 0;
            background: $normal;
            border-radius: 2px;
        }
    }
	h3{
		&:before{
			background: $blue;
		}
	}
    h5{
        font-size: 1.24rem;
        font-weight: 700;
        background: $gray;
        padding: 4px 15px;
        margin: 0 0 16px;
    }
    h6{
        position: relative;
        font-size: 1.2rem;
        font-weight: 700;
        padding: 0 0 0 18px;
        margin: 0 0 8px;
		@media screen and (max-width: $phone){
			padding: 0 0 0 12px;
			margin: 0 0 6px;
		}
        &:before{
            content: '';
            width: 12px;
            height: 12px;
            border: $normal 4px solid;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
    table{
        border: $border 1px solid;
        border-collapse: collapse;
        width: 100%;
        margin: 0 0 40px;
		@media screen and (max-width: $phone){
			margin: 0 0 24px;	
		}
        tbody,
        thead{
            tr{
                td,th{
                    padding: 15px;
                    text-align: left;
                    vertical-align: middle;
                    border: $border 1px solid;
                }
                th{
                    background: $gray;
					min-width: 200px;
					@media screen and (max-width: $phone){
						min-width: 100px;	
					}
				}
            }
        }
    }
    img{
        max-width: 100%;
    }
    .avatar{
        width: 88px;
        margin: 0 15px;
        img{
            width: 100%;
            border-radius: 50%;
        }
        figcaption{
            text-align: center;
            margin: 15px 0 0;
            font-size: 1rem;
        }
    }
    iframe{
        width: 100%;
        height: 300px;
        vertical-align: bottom;
        margin: 0 0 40px;
		@media screen and (max-width: $phone){
			height: 200px;
			margin: 0 0 24px;
		}
    }
	p{
		margin: 0 0 1.5rem;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
    ul,
    ol{
        margin: 0 0 40px;
		@media screen and (max-width: $phone){
			margin: 0 0 24px;	
		}
    }
	
	.charactor{
		li{
			@include flex();
			margin: 0 0 24px;
			@media screen and (max-width: $phone){
				margin: 0 0 16px;	
			}
			&:last-child{
				margin: 0;
			}
			&.right{
				.txt{
					&:after{
						content: '';
						width: 0;
						height: 0;
						position: absolute;
						top: 20px;
						left: -24px;
						border-style: solid;
						border-width: 8px 24px 8px 0;
						border-color: transparent $border transparent transparent;

					}
				}
			}
			&.left{
				figure{
					order: 2;
				}
				.txt{
					order: 1;
					&:after{
						content: '';
						width: 0;
						height: 0;
						position: absolute;
						top: 20px;
						right: -24px;
						border-style: solid;
						border-width: 8px 0 8px 24px;
						border-color: transparent transparent transparent $border;
					}
				}
			}
			figure{
				width: 50px;
				height: 50px;
				background: darken($gray, 2%);
				border-radius: 50%;
				overflow: hidden;
				margin: 8px 0 0;
				img{
					width: 100%;
				}
			}
			.txt{
				width: calc(100% - 50px - 32px);
				border: $border 4px solid;
				border-radius: 8px;
				padding: 20px;
				font-size: 1.08rem;
				margin: 0;
				position: relative;
				@media screen and (max-width: $phone){
					padding: 15px;
				}
			}
		}
	}
}