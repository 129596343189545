#site-header{
	width: 100%;
	padding: 20px $pc-side;
	border-bottom: rgba($border,0) 1px solid;
	position: fixed;
	top: 0;
	left: 0;
	@include flex();
	align-items: center;
	z-index: 2000;
	@media screen and (max-width: $tab){
		padding: 20px 40px;
	}
	@media screen and (max-width: $phone){
		padding: 20px $sp-side;
	}
	&:before{
		content: '';
		width: 100%;
		height: 0;
		background: #FFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: height 200ms $ease, box-shadow 200ms $ease;
	}
	&.active{
		@media screen and (min-width: $phone + 1px){
			padding: 16px $pc-side;
			&:before{
				height: 100%;
				box-shadow: 0 0 8px rgba($normal, .08);
			}
			.gnavi{
				ul{
					li{
						a{
							&:before{
								bottom: -26px;
							}
						}
					}
				}
			}
		}
	}
	a{
		color: $normal;
	}
	.logo{
		@media screen and (max-width: $phone){
			position: relative;
			z-index: 2;
		}
		svg{
			width: 240px;
			display: block;
			@media screen and (max-width: $tab){
				width: 180px;	
			}
			path,
			rect,
			circle{
				fill: $base;
			}
		}
	}
	.gnavi{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@media screen and (min-width: $phone + 1px){
			display: block !important;
		}
		@media screen and (max-width: $tab){
			position: relative;
			top: 0;
			left: 0;
			transform: translate(0);
		}
        @media screen and (max-width: $phone){
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: translate(0);
			&.active{
				.gnavi__box{
					opacity: 1;
				}
				.spview{
					opacity: 1;
				}
			}
        }
		&__box{
			@media screen and (max-width: $phone){
				background: #FFF;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				@include flex();
				align-items: center;
				justify-content: center;
				opacity: 0;
				transition: opacity 600ms $ease;
			}
		}
		ul{
			@include flex();
			align-items: center;
			@media screen and (max-width: $phone){
				display: block;
				text-align: center;
				transform: translateY(-32px);
			}
			li{
				margin: 0 0 0 3.3vw;
				@media screen and (max-width: $phone){
					margin: 0 0 5.6vh;
					&:last-child{
						margin: 0;
					}
				}
				&:first-child{
					margin-left: 0;
				}
				a{
					font-size: 1.04rem;
					position: relative;
					transition: color 120ms $ease;
					@media screen and (max-width: $phone){
						@include flexfont(1.64rem, 4);
						display: block;
					}
					&:before{
						content: '';
						background: $blue;
						width: 0;
						height: 3px;
						position: absolute;
						left: 50%;
						bottom: -16px;
						transform: translateX(-50%);
						transition: width 120ms $ease, bottom 120ms $ease;
					}
					&:hover{
						color: $blue;
						&:before{
							width: 48px;
						}
					}
				}
			}
		}
        .spview{
            display: none;
            @media screen and (max-width: $phone){
				width: 100%;
				display: block;
				text-align: center;
                position: absolute;
				left: 0;
				bottom: 24px;
				opacity: 0;
				z-index: 10;
				transition: opacity 600ms $ease;
				&__contact{
					@include btn();
					font-size: 1.12rem;
					margin: 0 $sp-side*2;
					i{
						display: inline-block;
						margin: 0 6px 0 0;
					}
				}
				&__privacy{
					font-size: .88rem;
					text-align: center;
					margin: 16px 0 0;
				}
            }
        }
	}
	.contact{
		color: #FFF;
		background: $blue;
		border: $blue 2px solid;
		padding: 6px 32px;
		border-radius: 999px;
		display: block;
		transition: box-shadow 200ms $ease;
		transition: all 240ms $ease;
		transition-property: background, color;
		@media screen and (max-width: $tab){
			padding: 5px 20px;
		}
		@media screen and (max-width: $phone){
			display: none;
		}
		i{
			margin: 0 6px 0 0;
			display: inline-block;
		}
		&:hover{
			background: #FFF;
			color: $blue;
		}
	}
	
	.menubtn{
		display: none;
		@media screen and (max-width: $phone){
			$size: 39px;
			width: $size;
			height: $size;
			background: $blue;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 50%;
			right: $sp-side;
			transform: translate(4px ,-50%);
			cursor: pointer;
			&:before,
			&:after{
				content: '';
				width: 16px;
				height: 1px;
				background: #FFF;
				position: absolute;
				top: $size/2 - 3;
				left: 50%;
				transform: translate(-50%, 0);
				transition: all 200ms $ease;
			}
			&:after{
				top: auto;
				bottom: $size/2 - 3;
			}
			&.active{
				&:before,
				&:after{
					top: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
				}
				&:after{
					top: auto;
					bottom: 50%;
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}
		}
	}
}