@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');

/* COLOR
------------------------------------------------------------*/
$normal: #242631;
$border: #e7eaec;
$base:   #0075c1;
$gray:   #f4f6f9;
$blue:   #0074c8;
$sky:    #56eafd;
$yellow: #fffc0e;
$link:   darken($blue, 12%);


/* SITE SETTING
------------------------------------------------------------*/
$width: 1200px;
$tab:   $width - 1px;
$phone: 768px;

$pc-side: 40px;
$sp-side: 16px;


$ease: cubic-bezier(0.65, 0, 0.35, 1);


@mixin flex($parent:wrap , $child:space-between){
	display:-webkit-flex;
	display:flex;
	-webkit-flex-flow:row $parent;
	flex-flow:row $parent;
	-webkit-justify-content:$child;
	justify-content:$child;
}

@mixin grd($before: $sky, $after: $blue){
	background-color: $before;
	background-image: linear-gradient(315deg, $before 0%, $after 74%);
}

@mixin flexfont($min: 1rem, $size: 30){
	font-size: calc(#{$min} + #{$size} * (100vw - 450px)/ 900);
}

@mixin ami($color){
	background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15 15"><rect x="0" y="0" width="3" height="3" fill="#{$color}" stroke-width="0" /></svg>');
}

@mixin btn($fontsize: 1.24rem){
	background: $blue;
	border: $blue 2px solid;
	color: #FFF;
	font-size: $fontsize;
	display: inline-block;
	padding: 8px 52px;
	border-radius: 999px;
	transition: all 240ms $ease;
	transition-property: background, color;
	@media screen and (max-width: $phone){
		display: block;
		padding: 8px 0;
	}
    i{
        display: inline-block;
        &.before{
            margin: 0 8px 0 0;
        }
        &.after{
            margin: 0 8px 0 0;
        }
    }
	&:hover{
		background: #FFF;
		color: $blue;
	}
}

@mixin fontawesome($c){
	content: "#{$c}";
	font-family: "Font Awesome 6 Free";
	font-weight: 900;
}

@font-face {
	font-family: 'Yu Gothic';
	src: local('Yu Gothic Medium');
	font-weight: 100;
}
@font-face {
	font-family: 'Yu Gothic';
	src: local('Yu Gothic Medium');
	font-weight: 200;
}
@font-face {
	font-family: 'Yu Gothic';
	src: local('Yu Gothic Medium');
	font-weight: 300;
}
@font-face {
	font-family: 'Yu Gothic';
	src: local('Yu Gothic Medium');
	font-weight: 400;
}
@font-face {
	font-family: 'Yu Gothic';
	src: local('Yu Gothic Medium');
	font-weight: 500;
}
@font-face {
	font-family: 'Yu Gothic';
	src: local('Yu Gothic Bold');
	font-weight: bold;
}

@mixin font(){
	font-family: 'Noto Sans JP', 'Yu Gothic','游ゴシック',YuGothic,'Hiragino Kaku Gothic Pro','メイリオ', Meiryo,'MS Pゴシック', Helvetica, Arial, Verdana, sans-serif;
	font-weight:500;
	-webkit-font-feature-settings : 'palt';
	font-feature-settings : 'palt';
	letter-spacing:0.024em;
}

@mixin min(){
	font-family: '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
	font-weight:500;
	-webkit-font-feature-settings : 'palt';
	font-feature-settings : 'palt';
	letter-spacing:0.04em;
}

@mixin default($width:auto, $font:14px, $color:#000, $link:#D00, $bg:#FFF, $pad: 0){
	*{
		margin:0;
		padding:0;
	}
	*, *:before, *:after {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	html{
		-webkit-text-size-adjust: none;
	}
	body{
		color: $color;
		background: $bg;
		font-size: $font;
		line-height: 1.8;
		@include font();
	}
	h1,h2,h3,h4,h5,h6{
		font-weight: 500;
	}
	img {
		max-width: 100%;
		height: auto;
		-ms-interpolation-mode: bicubic;
		vertical-align: bottom;
		image-rendering: -webkit-optimize-contrast;
		user-select: none;
	}
	i,strong{
		font-style: normal;
	}
	a{
		color: $link;
		text-decoration: none;
		outline: none;
		cursor: pointer;
		&:hover{
			color: lighten($link,10%);
            text-decoration: none;
			img{
				opacity:.8;
		  	}
		}
	}
	.clear{
		clear: both;
	}
	.clearfix:after{
		content: '';
		display: block;
		clear: both;
	}
	.display{
		width: $width;
		margin: 0 auto;
		padding: 0 $pad;
		position: relative;
		top: 0;
		left: 0;
	}
    ul,ol{
        list-style: none;
    }
	
	@media screen and (max-width: $tab){
		input[type='text'],
		input[type='tel'],
		input[type='email'],
		input[type='password'],
		input[type='submit'],
		input[type='button'],
		select,
		textarea{
			-webkit-appearance: none;
		}
	}
}