.site-privacy{
	.privacybox{
		&__ttl{
			font-size: 1.44rem;
			font-weight: 700;
			line-height: 1;
			text-align: center;
			margin: 0 0 24px;
			@media screen and (max-width: $phone){
				font-size: 1.12rem;
				color: #FFF;
				font-weight: 500;
				background: $blue;
				padding: 12px;
				margin: 0 0 12px;
			}
		}
		&__context{
			margin: 0 16%;
			text-align: justify;
			@media screen and (max-width: $phone){
				margin: 0;
			}
		}
		&__data{
			margin: 8px 0 0;
			background: $gray;
			padding: 32px;
			border-radius: 3px;
			@media screen and (max-width: $phone){
				padding: $sp-side;	
			}
			&:first-of-type{
				margin-top: 40px;
				@media screen and (max-width: $phone){
					margin-top: 24px;	
				}
			}
			dt{
				font-size: 1.16rem;
				font-weight: 700;
				border-bottom: #FFF 4px solid;
				padding: 0 0 10px;
				margin: 0 0 14px;
			}
			dd{
				font-size: .96rem;
				text-align: justify;
				margin: 20px 0 0;
				&:first-of-type{
					margin-top: 0;
				}
				ul,
				ol{
					background: #FFF;
					margin: 24px 0 0;
					padding: 24px;
					border-radius: 3px;
					@media screen and (max-width: $phone){
						padding: $sp-side;
					}
					li{
						margin: 0 0 4px;
						padding: 0 0 0 28px;
						position: relative;
						&:last-child{
							margin: 0;
						}
						&:before{
							content: '■';
							color: darken($gray, 16%);
							position: absolute;
							top: 0;
							left: 0;
						}
					}
				}
				ol{
					li{
						@for $i from 1 through 20{
							&:nth-child(#{$i}){
								&:before{
									content: '(#{$i})';
									color: $blue;
								}
							}
						}
					}
				}
			}
		}
		&__addr{
			text-align: center;
			border: darken($gray, 4%) 2px solid;
			padding: 24px;
			h3{
				color: $blue;
				font-size: 1.08rem;
				font-weight: 700;
				margin: 0 0 6px;
			}
		}
	}
}