.site-news{
	.news-wrap{
		width: 80%;
		margin: 0 auto;
		@media screen and (max-width: $phone){
			width: auto;	
		}
	}
	.site-common__message{
		@media screen and (max-width: $phone){
			margin: 0 0 24px;
			text-align: left;
			&:before{
				margin: 0 0 4px;
			}
		}
	}
	.newslist{
		&__item{
			transform: translateY(-24px);
			&__box{
				color: $normal;
				@include flex();
				align-items: center;
				padding: 24px 0;
				padding-right: 40px;
				border-bottom: $border 1px solid;
				position: relative;
				transition: all 120ms $ease;
				transition-property: color, transform, box-shadow;
				&:after{
					@include fontawesome('\f061');
					color: $blue;
					font-size: .96rem;
					position: absolute;
					top: 50%;
					right: 8px;
					transform: translateY(-50%);
					transition: right 120ms 40ms $ease;
				}
				&:hover{
					color: $blue;
					transform: scale(1.02);
					z-index: 2;
					@media screen and (max-width: $phone){
						transform: scale(1);
					}
					&:after{
						right: 0;
					}
				}
				> time{
					width: 92px;
					font-size: .72rem;
					color: #FFF;
					background: $blue;
					text-align: center;
					padding: 4px 0;
					border-radius: 2px;
					@media screen and (max-width: $phone){
						width: 88px;	
					}
				}
				> h2{
					width: calc(100% - 92px);
					font-size: 1.04rem;
					font-weight: 700;
					padding: 0 0 0 24px;
					@media screen and (max-width: $phone){
						width: calc(100% - 88px);
						padding: 0 0 0 16px;
					}
				}
			}
		}
	}
	nav.pager{
		margin: 40px 0 0;
		@include flex();
		align-items: center;
		justify-content: center;
		@media screen and (max-width: $phone){
			margin: 16px 0 0;	
		}
		a,span{
			$size: 36px;
			font-size: .88rem;
			color: $normal;
			width: $size;
			height: $size;
			border: $border 2px solid;
			margin: 0 6px;
			border-radius: 50%;
			@include flex();
			align-items: center;
			justify-content: center;
		}
		a{
			background: #FFF;
			transition: all 80ms $ease;
			&:hover{
				color: #FFF;
				background: $blue;
				border-color: $blue;
				transform: scale(1.1);
			}
		}
		span{
			background: darken($gray, 8%);
			border-color: darken($gray, 8%);
		}
	}
}