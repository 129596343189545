.site-company{
	.history{
		background: #FFF;
		padding: 24px;
		@include flex();
		align-items: center;
		box-shadow: 0 0 16px rgba($normal, .08);
		@media screen and (max-width: $phone){
			padding: $sp-side;
			display: block;
		}
		&__pic{
			width: 300px;
			@media screen and (max-width: $phone){
				width: auto;	
			}
			img{
				width: 100%;
			}
		}
		&__data{
			width: calc(100% - 300px);
			padding: 0 0 0 40px;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
				margin: 20px 0 0;
			}
			&__txt{
				font-size: 1.04rem;
				margin: 0 0 20px;
			}
			&__logo{
				img{
					width: 320px;
					vertical-align: bottom;
					@media screen and (max-width: $phone){
						width: 240px;	
					}
				}
				figcaption{
					margin: 8px 0 0;
				}
			}
		}
	}
	.gmap{
		border-top: darken($gray, 8%) 1px solid;
		height: 360px;
		iframe{
			width: 100%;
			height: 100%;
			vertical-align: bottom;
			filter: grayscale(1);
		}
	}
}