.mainhead{
    padding-top: 180px;
	padding-bottom: 90px;
    position: relative;
	z-index: 0;
	@media screen and (max-width: $phone){
		padding: 92px 0;
	}
    &:after{
        content: '';
        width: 100%;
        height: 100vh;
        position: absolute;
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
        left: 0;
        top: 0;
        z-index: 1;
    }
    &__title{
		color: $blue;
		font-size: 1.44rem;
		font-weight: 700;
		line-height: 1.24;
		text-align: center;
		position: relative;
		z-index: 2;
		@media screen and (max-width: $phone){
			font-size: 1rem;	
		}
		&:before{
			content: attr(data-eng);
			font-family: 'Raleway', sans-serif;
			font-size: 4.32rem;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: .08em;
			margin: 0 0 2px;
			display: block;
			@media screen and (max-width: $phone){
				font-size: 2.44rem;	
			}
		}
		&.min{
			@media screen and (max-width: $phone){
				font-size: 1.32rem;	
			}
			&:before{
				font-size: 2.64rem;
				@media screen and (max-width: $phone){
					font-size: 1rem;
					margin: 0 0 6px;
				}
			}
		}
    }
	&__bread{
		position: absolute;
		top: 88px;
		right: $pc-side + 8px;
		z-index: 2;
		@media screen and (max-width: $phone){
			top: auto;
			right: auto;
			bottom: 24px;
			left: $sp-side + 2px;
		}
		ol{
			@include flex();
			align-items: center;
			justify-content: flex-end;
			font-size: .72rem;
			li{
				color: $blue;
				&:before{
					@include fontawesome('\f105');
					font-size: .64rem;
					display: inline-block;
					margin: 0 8px;
				}
				&:first-child{
					&:before{
						@include fontawesome('\f015');
						margin: 0 4px 0 0;
						opacity: 1;
					}
				}
				a{
					color: $blue;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
	}
	&__scroll{
		width: 1px;
		height: 80px;
		position: absolute;
		right: 50%;
		bottom: 0;
		z-index: 2;
		transform: translateY(50%);
		&__line{
			width: 100%;
			height: 100%;
			background: $blue;
			position: absolute;
			bottom: 0;
			left: 0;
			animation: sc 1200ms $ease infinite;
		}
	}
    &__efect{
        content: '';
        width: 100%;
        height: 100vh;
        position: absolute;
        background: #FFF;
        top: 0;
        left: 0;
		overflow: hidden;
        z-index: -1;
        #particles{
            width: 100%;
            height: 100%;
			overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

