.site-feature{	
	.featuremain{
		height: 38vw;
		min-height: 500px;
		position: relative;
		overflow: hidden;
		@include flex();
		align-items: center;
		@media screen and (max-width: $phone){
			height: auto;
			min-height: 1px;
			padding: 20px 0 0;
			border-bottom: $border 1px solid;
			display: block;
		}
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 100%;
			left: 0;
			box-shadow: 0 0 32px rgba($normal, .12);
			z-index: 2;
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		&__bg{
			width: 100%;
			height: 100%;
			background: url(../../images/feature/main_mockup_2x.jpg) no-repeat top center / cover;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			@media screen and (max-width: $phone){
				width: 100%;
				height: 56vmin;
				background: url(../../images/feature/main_mockup_2x.jpg) no-repeat top left / cover;
				position: relative;
				transform: translate(0) !important;
			}
		}
		&__box{
			padding: 0 6vw 0 56%;
			position: relative;
			z-index: 2;
			@media screen and (max-width: $tab){
				padding: 0 40px 0 calc(50% + 40px);
			}
			@media screen and (max-width: $phone){
				padding: 32px $sp-side*1.5;	
			}
			&__title{
				@include flexfont(2rem, 10);
				font-weight: 700;
				line-height: 1.33;
				margin: 80px 0 0;
				@media screen and (max-width: $phone){
					font-size: 1.88rem;
					line-height: 1.56;
					margin: 0;
					text-align: center;
				}
			}
			&__txt{
				@include flexfont(.92rem, 2);
				margin: 16px 0 0;
				@media screen and (max-width: $tab){
					br{
						display: none;
					}	
				}
				@media screen and (max-width: $phone){
					font-size: 1.04rem;	
				}
				strong{
					color: $blue;
				}
			}
			&__btn{
				margin: 24px 0 0;
				a{
					@include btn();
					@media screen and (max-width: $phone){
						text-align: center;	
					}
				}
			}
		}
	}
	
	.multi{
		width: 100%;
		padding: 24px 0 0;
		text-align: center;
		position: relative;
		overflow: hidden;
		@media screen and (max-width: $phone){
			padding: 0;	
		}
		&__pic{
			width: 880px;
			margin: 0 auto;
			@media screen and (max-width: $phone){
				width: 72%;
			}
			img{
				width: 100%;
			}
		}
		&__txt{
			font-size: 1.08rem;
			margin: 48px 0 24px;
			@media screen and (max-width: $phone){
				margin: 24px $sp-side 16px;
			}
		}
		&__data{
			font-size: .88rem;
			padding: 24px;
			background: darken($gray, 2%);
			max-width: 880px;
			margin: 0 auto;
			@media screen and (max-width: $phone){
				margin: 0 $sp-side;
				padding: $sp-side;
			}
			dt{
				font-size: .92rem;
				font-weight: 700;
			}
		}
		&__lgtxt{
			font-size: 24.4rem;
			font-weight: 700;
			color: darken($gray, 4%);
			line-height: 1;
			white-space: nowrap;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
			@media screen and (max-width: $phone){
				font-size: 4.8rem;
				top: 16.6%;
			}
		}
	}

	.localnav{
		position: sticky;
		top: 75px;
		left: 0;
		background: darken($gray, 2%);
		padding: 20px 0;
		z-index: 10;
		@media screen and (max-width: $tab){
			top: 70px;	
		}
		@media screen and (max-width: $phone){
			position: relative;
			top: 0;
			padding: $sp-side;
			margin: 0 $sp-side;
		}
		&__list{
			@include flex();
			align-items: center;
			@media screen and (max-width: $tab){
				justify-content: flex-start;
				margin: 0 0 0 -24px;
			}
			@media screen and (max-width: $phone){
				margin: 0;
				padding: 0;
				display: block;
			}
			li{
				font-size: .92rem;
				@media screen and (max-width: $tab){
					font-size: 1rem;
					width: 33.3%;
					padding: 0 0 0 24px;
					margin: 2px 0;
					text-align: center;
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
				}
				a{
					display: block;
					padding: 6px 24px;
					color: lighten($normal, 20%);
					transition: all 120ms $ease;
					@media screen and (max-width: $phone){
						font-size: 1rem;
						padding: 12px $sp-side;
						padding-right: $sp-side + 20px;
						background: #FFF;
						text-align: left;
						position: relative;
						&:before{
							@include fontawesome('\f107');
							color: $blue;
							position: absolute;
							top: 50%;
							right: $sp-side;
							transform: translateY(-50%);
						}
					}
					&:hover{
						color: $blue;
					}
				}
				&.active{
					a{
						color: $blue;
						background: #FFF;
						border-radius: 999px;
						@media screen and (max-width: $phone){
							border-radius: 0;	
						}
					}
				}
			}
		}	
	}
	
	.features{
		padding-left: 8vw;
		padding-right: 8vw;
		@media screen and (max-width: $tab){
			padding-left: 40px;
			padding-right: 40px;
		}
		@media screen and (max-width: $phone){
			padding-left: $sp-side;	
			padding-right: $sp-side;	
		}
		&__clum{
			@include flex();
			align-items: center;
			@media screen and (max-width: $tab){
				display: block;	
			}
			&__pic{
				width: 52%;
				position: relative;
				@media screen and (max-width: $tab){
					width: 64%;
					padding: 80px 0 0;
					margin: 0 auto;
				}
				@media screen and (max-width: $phone){
					width: 88%;
					padding: 40px 0 0;
				}
				img{
					width: 100%;
					display: block;
					position: relative;
					z-index: 2;
					opacity: 0;
					transform: translateY(20px);
					transition: all 600ms 200ms $ease;
					transition-property: transform, opacity;
					&.phone{
						max-width: 280px;
						margin: 0 auto;
						@media screen and (max-width: $phone){
							max-width: 160px;	
						}
					}
				}
				&__numb{
					@include flexfont(9rem, 20);
					font-weight: 700;
					line-height: 1;
					color: $blue;
					position: absolute;
					display: block;
					top: -88px;
					left: 0;
					z-index: 1;
					opacity: 0;
					transform: translateY(40px);
					transition: all 400ms $ease;
					transition-property: transform, opacity;
					@media screen and (max-width: $tab){
						top: -16px;
					}
					@media screen and (max-width: $phone){
						font-size: 6rem;
						top: -12px;
						left: -$sp-side;
					}
				}
				&._active{
					img{
						opacity: 1;
						transform: translateY(0);
					}
					.features__clum__pic__numb{
						opacity: 1;
						transform: translateY(0);
					}
				}
			}
			&__data{
				width: 48%;
				max-width: 560px;
				margin: 0 auto;
				padding: 0 0 0 48px;
				@media screen and (max-width: $tab){
					width: auto;
					max-width: 100%;
					padding: 0 40px;
					margin: 40px 0 0;
				}
				@media screen and (max-width: $phone){
					padding: 0 $sp-side;
					margin: 24px 0 0;
				}
				.featdata{
					&__title{
						@include flexfont(1.44rem, 10);
						font-weight: 700;
						line-height: 1.44;
						margin: 0 0 16px;
						transform: translateX(-4px);
						@media screen and (max-width: $tab){
							text-align: center;
							transform: translate(0);
						}
						&:before{
							content: attr(data-eng);
							color: $blue;
							font-size: .8rem;
							left: .12em;
							line-height: 1;
							display: block;
							margin: 0 0 8px;
							transform: translateX(4px);
						}
					}
					&__dataset{
						line-height: 1.66;
						font-weight: 700;
						margin: 0 0 20px;
						@media screen and (max-width: $tab){
							text-align: center;	
						}
						dt{
							font-size: 1.08rem;
							margin: 0 0 16px;
						}
						dd{
							font-size: 1.12rem;
							color: $blue;
							background: #FFF;
							border: $blue 2px solid;
							border-radius: 4px;
							padding: 12px 20px;
						}
					}
					&__strong{
                        font-size: 1.12rem;
                        color: $blue;
						background: #FFF;
						line-height: 1.66;
						font-weight: 700;
                        border: $blue 2px solid;
						border-radius: 4px;
                        padding: 12px 20px;
						margin: 0 0 20px;
					}
					&__txt{
						font-size: .96rem;
						text-align: justify;
					}
					&__icons{
						margin: 24px 0 0;
						@include flex();
						justify-content: flex-end;
						li{
							font-size: .92rem;
							color: $blue;
							background: #FFF;
							border: $blue 1px solid;
							border-radius: 4px;
							margin: 0 0 0 12px;
							padding: 3px 12px;
							i{
								color: $blue;
								display: inline-block;
								margin: 0 4px 0 0;
							}
							&:first-child{
								margin-left: 0;
							}
						}
					}
				}
			}
		}
		&__alt{
			background: darken($gray, 1%);
			padding: 24px;
			margin: 80px 0 0;
			line-height: 1.6;
			border-radius: 4px;
			position: relative;
			@media screen and (max-width: $phone){
				margin: 24px 0 0 ;
				padding: $sp-side;
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 16px 32px 16px;
				border-color: transparent transparent darken($gray, 1%) transparent;
				position: absolute;
				top: 0;
				right: 8%;
				transform: translateY(-100%);
				@media screen and (max-width: $phone){
					display: none;
				}
			}
			&__list{
				@include flex();
				align-items: center;
				justify-content: flex-start;
				@media screen and (max-width: $phone){
					display: block;	
				}
				li{
					font-size: .92rem;
					margin: 0 24px 0 0;
					@media screen and (max-width: $phone){
						margin: 0 0 16px;	
					}
					&.pic{
						width: 15%;
						@media screen and (max-width: $phone){
							width: auto;
							text-align: center;
						}
					}
					&.title{
						font-size: 1.08rem;
						font-weight: 700;
						@media screen and (max-width: $phone){
							font-size: 1.24rem;
							text-align: center;
						}
					}
					&:last-child{
						margin: 0;
					}
					img{
						width: 100%;
						@media screen and (max-width: $phone){
							width: 56%;	
						}
					}
				}
			}
			&__txt{
				margin: 16px 0 0;
				padding: 16px 0 0;
				border-top: $border 1px solid;
				font-size: .92rem;
			}
		}
		&__sub{
			background: #FFF;
			margin: 80px auto 0;
			padding: 4vw;
			position: relative;
			border: $border 1px solid;
			border-radius: 4px;
			@include flex();
			align-items: flex-start;
			@media screen and (max-width: $phone){
				margin: 24px auto 0;
				padding: $sp-side;
				display: block;
			}
			&:before{
				content: '';
				width: 1px;
				height: 100%;
				background: $border;
				position: absolute;
				top: 0;
				left: 50%;
				@media screen and (max-width: $phone){
					display: none;
				}
			}
			&__item{
				width: 50%;
				padding: 0 4.8% 0 0;
				@include flex();
				align-items: center;
				@media screen and (max-width: $phone){
					width: auto;
					padding: $sp-side;
					border-bottom: $border 1px solid;
					&:last-of-type{
						border-bottom: none;
					}
				}
				&:nth-child(2n+2){
					padding: 0 0 0 4.8%;
					@media screen and (max-width: $phone){
						padding: $sp-side;	
					}
				}
				figure{
					width: 28%;
					&.vertical{
						width: 64%;
						margin: 0 auto;
						@media screen and (max-width: $phone){
							margin: 0 auto 20px;
						}
						+ dl{
							padding: 0;
							width: 100%;
							dt{
								text-align: center;
							}
						}
					}
					img{
						width: 100%;
					}
				}
				dl{
					width: 72%;
					margin: 16px 0 0;
					padding: 0 0 0 40px;
					@media screen and (max-width: $phone){
						margin: 0;
						padding: 0 0 0 24px;
					}
					&.full{
						width: auto;
						padding: 0;
						margin: 32px 0 0;
						&:first-of-type{
							margin: 0;
						}
					}
					dt{
						color: $blue;
						@include flexfont(1.1rem, 3);
						line-height: 1.6;
						font-weight: 700;
						margin: 0 0 12px;
					}
					dd{
						@include flexfont(.88rem, 1.5);
						text-align: justify;
						margin: .92rem 0 0;
						&:first-of-type{
							margin-top: 0;
						}
					}
				}
			}
		}
		&__sml{
			font-size: .88rem;
			background: $gray;
			margin: 32px 0 0;
			padding: 24px;
		}
    &__cosoji{
      background: $gray;
      padding: 24px;
      margin: 24px 0 0;
      border-radius: 4px;
      @media screen and (max-width: $phone){
        padding: 16px;
        margin: 16px 0 0;
      }
      &__title{
        font-size: 1.12rem;
        width: 100%;
        @include flex();
        align-items: flex-end;
        justify-content: flex-start;
        @media screen and (max-width: $phone){
          font-size: 1rem;
        }
        figure{
          width: 10rem;
          margin: 0 .8rem;
          @media screen and (max-width: $phone){
            margin: .56rem .4rem 0 0;
          }
        }
      }
      &__grid{
        display: grid;
        grid-template-columns: 40rem 1fr;
        gap: 3.2rem;
        margin: 2.4rem 0 0;
        @media screen and (max-width: $tab){
          grid-template-columns: 1fr;
        }
        @media screen and (max-width: $phone){
          gap: 16px;
        }
        figure{
          img{
            width: 100%;
          }
        }
        &__frame{
          width: 100%;
          padding: calc(100% / 16 * 9) 0 0;
          position: relative;
          iframe{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: .4rem;
          }
        }
      }
    }
  }
	
	.otherlist{
		@include flex();
		@media screen and (max-width: $phone){
			display: block;
		}
		&__item{
			width: 32%;
			background: #FFF;
			padding: 32px;
			border-radius: 4px;
			box-shadow: 0 0 16px rgba($normal, .04);
			@media screen and (max-width: $phone){
				width: auto;
				padding: $sp-side*1.5;
				padding-top: 32px;
				margin: 0 0 16px;
				&:last-child{
					margin: 0;
				}
			}
			figure{
				width: 88%;
				margin: 0 auto;
				img{
					width: 100%;
				}
			}
			dl{
				margin: 24px 0 0;
				dt{
					font-size: 1.12rem;
					color: $blue;
					font-weight: 700;
					text-align: center;
				}
				dd{
					font-size: .96rem;
					line-height: 1.66;
					margin: 6px 0 0;
					text-align: justify;
				}
			}
		}
	}
	
	.version{
		background: #FFF;
		padding: 20px;
		box-shadow: 4px 4px 24px rgba($normal, .064);
		@media screen and (max-width: $phone){
			padding: $sp-side;	
		}
		&__title{
			color: #FFF;
			font-size: 1.12rem;
			font-weight: 700;
			background: $blue;
			padding: 10px 0;
			margin: 0 0 24px;
			text-align: center;
			display: block;
			@media screen and (max-width: $phone){
				padding: 8px 0;
				margin: 0 0 20px;
			}
		}
        &__list{
            @include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
            &__item{
                width: calc(50% - 12px);
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 20px;
					&:last-child{
						margin: 0;
					}
				}
				h4{
					background: darken($gray, 4%);
					font-weight: 700;
					padding: 8px;
					text-align: center;
				}
				dl{
					@include flex();
					margin: 16px 0 0;
					dt{
						color: $blue;
						width: 100px;
					}
					dd{
						width: calc(100% - 100px);
					}
				}
            }
        }
		.ssl{
			margin: 20px 0 0;
			font-size: .92rem;
			dt{
				background: darken($gray, 4%);
				padding: 2px 16px;
				border-radius: 3px;
				display: inline-block;
				margin: 0 0 8px;
			}
		}
	}
	.support{
		border: darken($gray, 2%) 8px solid;
		padding: 32px 48px;
		@media screen and (max-width: $phone){
			padding: 24px $sp-side;	
		}
		&__description{
			margin: 0 0 16px;
			padding: 0 0 16px;
			border-bottom: $border 1px solid;
			text-align: center;
			@include flex();
			align-items: center;
			justify-content: center;
			dt{
				font-size: 1.2rem;
				color: $blue;
				font-weight: 700;
				width: 100%;
				margin: 0 0 24px;
				@media screen and (max-width: $phone){
					font-size: 1.12rem;
					margin: 0 0 16px;
				}
			}
			dd{
				font-size: 1.12rem;
				margin: 0 64px 0 0;
				@media screen and (max-width: $phone){
					margin: 0 16px 0 0;
					font-size: .92rem;
				}
				&:last-of-type{
					margin-right: 0;
				}
				figure{
					width: 144px;
					@media screen and (max-width: $phone){
						width: 72px;	
					}
					img{
						width: 100%;
					}
				}
			}
		}
		&__alt{
			text-align: center;
		}
	}
}