.site-index{
	.firstview{
		height: 100vh;
		min-height: 780px;
		background: #FFF;
		position: relative;
		overflow: hidden;
		@media screen and (max-width: $tab){
			height: auto;
			min-height: 1px;
			border-bottom: $border 1px solid;
		}
		&__box{
			width: 100%;
			height: 100%;
			padding: 40px 6.4% 0 7.2%;
			position: absolute;
			top: 0;
			left: 0;
			@include flex();
			align-items: center;
			@media screen and (max-width: $tab){
				position: relative;
				padding: 144px 40px;
			}
			@media screen and (max-width: $phone){
				padding: 88px $sp-side*2 144px;	
			}
			&__bg{
				content: '';
				width: 100%;
				height: 100%;
				background: url(../../images/index/fv_polygon_bg_2x.png) no-repeat right bottom / cover;
				position: absolute;
				top: 0;
				right: 0;
				@media screen and (max-width: $tab){
					background: url(../../images/index/fv_polygon_bg_sp_2x.png) no-repeat right top / cover;
				}
			}
            &__policy{
                position: absolute;
                left: 4vw;
                bottom: 32px;
                z-index: 2;
				@include flex();
				justify-content: flex-start;
				align-items: flex-end;
				@media screen and (max-width: $tab){
					left: 40px;
				}
				@media screen and (max-width: $phone){
					left: $sp-side;
					bottom: $sp-side;
				}
				&__pic{
					width: 145px;
					margin: 0 20px 0 0;
					&.sp{
						display: none;
						@media screen and (max-width: $phone){
							display: block;	
						}
					}
					@media screen and (max-width: $phone){
						display: none;
						width: 145px;
					}
					img{
						width: 100%;
					}
				}
				&__link{
					color: lighten($normal, 16%);
					font-size: .82rem;
					text-decoration: underline;
				}
            }
			&__pic,
			&__data{
				position: relative;
				z-index: 2;
			}
			&__pic{
				width: 64%;
				@include flex();
				align-items: center;
				justify-content: center;
				@media screen and (max-width: $tab){
					width: 72%;
					margin: 0 auto;
					order: 1;
				}
				@media screen and (max-width: $phone){
					width: 96%;	
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: center;
					@media screen and (max-width: $tab){
						width: 100%;
						height: auto;
					}
				}
			}
			&__data{
				width: 36%;
				padding: 0 3.66vw 0 0;
				@include flex();
				justify-content: center;
				@media screen and (max-width: $tab){
					width: 100%;
					padding: 0 40px;
					padding-top: 16px;
					text-align: center;
					order: 2;
				}
				@media screen and (max-width: $phone){
					padding: 16px 0 0;	
				}
				.titlearea{
					line-height: 1.6;
					@media screen and (max-width: $tab){
						line-height: 1.2;	
					}
					&__catch{
						@include flexfont(1.1rem, 4);
						font-weight: 700;
						margin: 0 0 4px;
						transform: translateX(.16em);
						@media screen and (max-width: $tab){
							font-size: 1.56rem;	
						}
						@media screen and (max-width: $phone){
							font-size: 1.12rem;	
						}
						strong{
							@include flexfont(1.5rem, 8);
							color: $blue;
							@media screen and (max-width: $tab){
								font-size: 3.2rem;
							}
							@media screen and (max-width: $phone){
								font-size: 2rem;	
							}
						}	
					}
					&__title{
						@include flexfont(2.1rem, 15);
						font-weight: 700;
						white-space: nowrap;
						line-height: 1.36;
						position: relative;
						@media screen and (max-width: $tab){
							br{
								display: none;
							}	
						}
						@media screen and (max-width: $phone){
							font-size: 1.44rem;	
						}
						
						.tip{
							color: #FFF;
							background: $blue;
							@include flexfont(.72rem, 2);
							padding: 4px;
							border-radius: 4px;
							margin: 0 4px;
							vertical-align: middle;
							display: inline-block;
							-webkit-writing-mode: vertical-rl;
							-moz-writing-mode: vertical-rl;
							-ms-writing-mode: tb-rl;
							writing-mode: vertical-rl;
							-webkit-text-orientation: upright;
							text-orientation: upright;
							-webkit-font-feature-settings : normal;
							font-feature-settings : normal;
							transform: translateY(-10%);
							@media screen and (max-width: $phone){
								font-size: .72rem;
								margin: 0 2px;
								transform: scale(.8) translateY(-10%);
							}
						}
					}
					&__context{
						@include flexfont(.8rem, 2);
						margin: 12px 0;
						padding: 0 0 0 126px;
						transform: translateX(.18em);
						position: relative;
						@media screen and (max-width: $tab){
							font-size: 1.08rem;
							padding: 0;
							transform: translate(0);
						}
						@media screen and (max-width: $phone){
							margin: 8px 0 0;
							line-height: 1.44;
						}
						&:before{
							content: attr(data-tip);
							font-size: 1rem;
							width: 110px;
							height: 100%;
							background: darken($gray, 3%);
							color: darken($blue, 20%);
							border-radius: 4px;
							position: absolute;
							top: 0;
							left: 0;
							@include flex();
							align-items: center;
							justify-content: center;
							@media screen and (max-width: $tab){
								margin: 0 12px 0 0;
								padding: 4px 0;
								display: inline-block;
								position: relative;
							}
							@media screen and (max-width: $phone){
								width: 100%;
								display: block;
								text-align: center;
								margin: 0 0 8px;
							}
						}
					}
					&__price{
						@include flexfont(1.2rem, 1.6);
						color: $blue;
						font-weight: 700;
						line-height: 1.66;
						transform: translateX(.18em);
						@media screen and (max-width: $tab){
							font-size: 1.56rem;
							transform: translate(0);
							br{
								display: none;
							}	
						}
						@media screen and (max-width: $phone){
							font-size: 1.2rem;
							margin: 16px 0 0;	
						}
						strong{
							@include flexfont(2.4rem, 2.4);
							line-height: 1;
							@media screen and (max-width: $tab){
								font-size: 2.4rem;	
							}
							@media screen and (max-width: $phone){
								font-size: 2.2rem;
							}
						}
					}
					&__btn{
						margin: 20px 0 0;
						a{
							@include btn();
							@include flexfont(1rem, 2);
							padding: 14px 40px;
							@media screen and (max-width: $phone){
								font-size: 1.16rem;	
							}
							i{
								margin: 0 12px 0 0;
								display: inline-block;
							}
						}
					}
					&__alt{
						margin: 16px 0 0;
						@include flexfont(.8rem, .8);
						@media screen and (max-width: $phone){
							font-size: .72rem;
							transform: scale(.9);
						}
					}
				}
			}
		}
		&__copy{
			color: #FFF;
			font-size: .72rem;
			text-transform: uppercase;
			white-space: nowrap;
			position: absolute;
			top: 50%;
			right: $pc-side;
			letter-spacing: .16em;
            -webkit-writing-mode: vertical-rl;
            -moz-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
			transform: translateY(-50%);
			@media screen and (max-width: $tab){
				color: $blue;	
			}
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		&__scroll{
			color: $blue;
			font-size: .8rem;
			text-transform: uppercase;
			white-space: nowrap;
			padding: 0 28px 108px 0;
			position: absolute;
			right: $pc-side;
			bottom: 0;
			z-index: 20;
			@media screen and (max-width: $phone){
				font-size: .72rem;
				right: $sp-side;
				padding: 0 20px 64px 0;
			}
			&__txt{
				letter-spacing: .08em;
				font-weight: 700;
				text-indent: .32em;
				position: absolute;
				top: -3px;
				right: 0;
				display: block;
				text-align: center;
				-webkit-writing-mode: vertical-rl;
				-moz-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
			}
			&:before{
				content: '';
				width: 2px;
				height: 100%;
				background: $blue;
				position: absolute;
				bottom: 0;
				left: 0;
				animation: sc 1600ms $ease infinite;
				@keyframes sc{
					0%{
						height: 100%;
						bottom: 0;
					}
					45%{
						height: 0;
						bottom: 0;
					}
					55%{
						height: 0;
						bottom: 100%;
					}
					100%{
						height: 100%;
						bottom: 0;
					}
				}
			}
		}
	}
}