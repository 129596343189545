.site-flow{
	.flowlist{
		&__step{
			position: relative;
			@include flex();
			align-items: center;
			@media screen and (max-width: $tab){
				justify-content: flex-start;
				margin: 0 0 0 -16px;
			}
			@media screen and (max-width: $phone){
				display: block;
				margin: 0;	
			}
			&:before{
				content: '';
				width: 100%;
				height: 3px;
				background: darken($gray, 2%);
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				z-index: 1;
				@media screen and (max-width: $tab){
					display: none;
				}
			}
			&__item{
				width: 23.5%;
				text-align: center;
				position: relative;
				z-index: 2;
				@media screen and (max-width: $tab){
					width: 50%;
					padding: 0 0 0 16px;
					margin: 16px 0 0;
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 12px;
					&:last-child{
						margin: 0;
					}
				}
				@for $i from 1 through 4{
					&:nth-child(#{$i}){
						a{
							&:before{
								content: '#{$i}';
							}
						}
					}
				}
				a{
					font-size: 1.08rem;
					color: $link;
					background: #FFF;
					border: darken($gray, 2%) 3px solid;
					padding: 12px 20px;
					display: block;
					border-radius: 3px;
					position: relative;
					@media screen and (max-width: $phone){
						text-align: left;
						padding-left: 32px;
					}
					&:before{
						color: #FFF;
						width: 36px;
						height: 36px;
						border-radius: 50%;
						background: $blue;
						border: $blue 2px solid;
						position: absolute;
						top: 0;
						left: 8px;
						@include flex();
						align-items: center;
						justify-content: center;
						transform: translate(-50%, -50%);
						@media screen and (max-width: $phone){
							top: 50%;
							left: 0;
						}
					}
					&:hover{
						color: #FFF;
						background: $blue;
						border-color: $blue;
						&:before{
							background: #FFF;
							color: $blue;
						}
					}
				}
			}
		}
	}
	
	.rule{
		background: #FFF;
		padding: 32px;
		border-radius: 4px;
		text-align: center;
		@media screen and (max-width: $phone){
			padding: $sp-side;	
		}
		&.gray{
			background: $gray;
		}
		&__title{
			color: $blue;
			font-size: 1.12rem;
			font-weight: 700;
			margin: 0 0 8px;
		}
		&__txt{
			margin: 0 0 24px;
		}
	}
	
	.ban{
		background: $gray;
		padding: 24px;
		@media screen and (max-width: $phone){
			padding: $sp-side;	
		}
		li{
			font-size: .88rem;
			background: #FFF;
			padding: 24px;
			padding-left: 64px;
			margin: 0 0 2px;
			position: relative;
			color: lighten($normal, 16%);
			@media screen and (max-width: $phone){
				padding: $sp-side;
				padding-left: 64px;
			}
			&:before{
				@include fontawesome('\f06a');
				font-size: 1.32rem;
				color: darken($gray, 16%);
				position: absolute;
				top: 50%;
				left: 24px;
				transform: translateY(-50%);
			}
			&:last-child{
				margin: 0;
			}
		}
	}
	
	.trial{
		@include flex();
		&__pic{
			width: 400px;
			img{
				width: 100%;
			}
		}
		&__data{
			width: calc(100% - 400px);
			padding: 0 0 0 40px;
			&__list{
				li{
					font-size: .96rem;
					margin: 0 0 15px;
					padding: 0 0 15px;
					padding-left: 32px;
					border-bottom: $border 1px solid;
					position: relative;
					@for $i from 1 through 5{
						&:nth-child(#{$i}){
							&:before{
								content: '#{$i]}';
							}
						}
					}
					&:before{
						content: '';
						width: 22px;
						height: 22px;
						color: #FFF;
						line-height: 1;
						background: $blue;
						position: absolute;
						top: 2px;
						left: 0;
						@include flex();
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
	.flowtable{
		background: #FFF;
		tbody{
			tr{
				th{
					background: #FFF;
					border-right: $border 1px solid;
					@media screen and (max-width: $phone){
						background: darken($gray, 3%);	
					}
				}
				td{
					font-size: .92rem;
					dl{
						dt{
							color: $blue;
							font-size: 1.04rem;
							font-weight: 700;
							margin: 0 0 8px;
						}
						dd{
							margin: 20px 0 0;
							&:first-of-type{
								margin-top: 0;
							}
							strong{
								color: #FFF;
								background: $blue;
								display: inline-block;
								padding: 4px 24px;
								margin: 4px 0;
							}
							&.download{
								a{
									color: $blue;
									border: $blue 1px solid;
									border-radius: 3px;
									padding: 4px 24px;
									display: inline-block;
									transition: all 160ms $ease;
									&:hover{
										color: #FFF;
										background: $blue;
										padding: 4px 32px;
									}
									i{
										display: inline-block;
										margin: 0 8px 0 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}